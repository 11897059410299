import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid pb-4" }
const _hoisted_2 = { class: "card mx-auto mx-xxl-5" }
const _hoisted_3 = { class: "card-header text-center text-capitalize" }
const _hoisted_4 = { class: "card-body pt-2" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "card-header text-center text-capitalize" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "container-fluid text-center"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_12 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-6" }
const _hoisted_16 = { class: "input-group" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = {
  class: "badge bg-secondary border-0",
  type: "button",
  "data-bs-toggle": "modal",
  "data-bs-target": "#editGmail"
}
const _hoisted_19 = { class: "col-md-6" }
const _hoisted_20 = { class: "input-group" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = {
  class: "badge bg-secondary border-0",
  type: "button",
  "data-bs-toggle": "modal",
  "data-bs-target": "#editPassword"
}
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-sm-6" }
const _hoisted_26 = ["placeholder"]
const _hoisted_27 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_28 = { class: "col-sm-6" }
const _hoisted_29 = ["placeholder"]
const _hoisted_30 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-sm-3" }
const _hoisted_34 = ["value"]
const _hoisted_35 = { class: "col-sm-9" }
const _hoisted_36 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_37 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_38 = { class: "form-group" }
const _hoisted_39 = { class: "row" }
const _hoisted_40 = { class: "col-sm-6" }
const _hoisted_41 = { class: "form-check form-switch" }
const _hoisted_42 = ["checked"]
const _hoisted_43 = { class: "form-check-label" }
const _hoisted_44 = { class: "col-sm-6" }
const _hoisted_45 = ["value"]
const _hoisted_46 = ["value"]
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "form-group col" }
const _hoisted_49 = { class: "form-control-plaintext" }
const _hoisted_50 = {
  key: 0,
  type: "button",
  class: "col btn btn-sm btn-light",
  "data-bs-target": "#balance-modal",
  "data-bs-toggle": "modal"
}
const _hoisted_51 = { class: "col-md-6" }
const _hoisted_52 = { class: "card-header text-center text-capitalize" }
const _hoisted_53 = ["src"]
const _hoisted_54 = {
  key: 1,
  class: "container-fluid text-center"
}
const _hoisted_55 = { class: "form-group" }
const _hoisted_56 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_57 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_58 = { class: "form-group" }
const _hoisted_59 = { class: "row" }
const _hoisted_60 = { class: "col-sm-6" }
const _hoisted_61 = ["value"]
const _hoisted_62 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_63 = { class: "col-sm-6" }
const _hoisted_64 = ["placeholder"]
const _hoisted_65 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_66 = { class: "row" }
const _hoisted_67 = { class: "form-group col-sm-8" }
const _hoisted_68 = ["value"]
const _hoisted_69 = { class: "form-group col-sm-4" }
const _hoisted_70 = ["onUpdate:modelValue"]
const _hoisted_71 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_72 = { class: "row" }
const _hoisted_73 = { class: "form-group col-sm-6" }
const _hoisted_74 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_75 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_76 = { class: "form-group col-sm-6" }
const _hoisted_77 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_78 = {
  key: 0,
  class: "is-invalid"
}
const _hoisted_79 = { class: "form-group" }
const _hoisted_80 = {
  key: 0,
  class: "input-group"
}
const _hoisted_81 = ["placeholder"]
const _hoisted_82 = { class: "card-footer text-end" }
const _hoisted_83 = {
  class: "btn btn-info",
  type: "submit"
}
const _hoisted_84 = {
  class: "modal fade",
  id: "balance-modal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_85 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_86 = { class: "modal-content" }
const _hoisted_87 = { class: "modal-header" }
const _hoisted_88 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_89 = { class: "modal-body" }
const _hoisted_90 = { class: "form-group" }
const _hoisted_91 = { class: "form-control-plaintext" }
const _hoisted_92 = { class: "form-group" }
const _hoisted_93 = { class: "modal-footer" }
const _hoisted_94 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_95 = {
  class: "modal fade",
  id: "editGmail",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_96 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_97 = { class: "modal-content" }
const _hoisted_98 = { class: "modal-header" }
const _hoisted_99 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_100 = { class: "modal-body" }
const _hoisted_101 = { class: "mb-3" }
const _hoisted_102 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_103 = { class: "modal-footer" }
const _hoisted_104 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_105 = {
  type: "submit",
  class: "btn bg-gradient-primary"
}
const _hoisted_106 = {
  class: "modal fade",
  id: "editPassword",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_107 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_108 = { class: "modal-content" }
const _hoisted_109 = { class: "modal-header" }
const _hoisted_110 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_111 = { class: "modal-body" }
const _hoisted_112 = { class: "mb-3" }
const _hoisted_113 = { class: "input-group" }
const _hoisted_114 = ["onUpdate:modelValue", "placeholder", "type"]
const _hoisted_115 = { class: "modal-footer" }
const _hoisted_116 = {
  type: "button",
  class: "btn bg-gradient-secondary",
  "data-bs-dismiss": "modal"
}
const _hoisted_117 = {
  type: "submit",
  class: "btn bg-gradient-primary"
}

import StorageService from '@/services/StorageService'
import { ErrorMessage, Field, Form } from 'vee-validate'
import dayjs from 'dayjs'
import Driver from '@/models/Driver'
import DriverRepository from '@/repositories/DriverRepository'
import { Constants } from '@/constants/Constants'
import ToastService from '@/services/ToastService'
import ImageLoader from '@/components/ImageLoader.vue'
import i18n from '@/plugins/i18n'
import { onBeforeMount, ref, Ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useDriversStore } from '@/services/stores/DriversStore'
import router from '@/router'
import DateHelper from '@/helpers/DateHelper'
import { mixed, object, date, string } from 'yup'
import { useLoadingState } from '@/services/stores/LoadingState'
import { hide } from '@/helpers/ModalHelper'
import { StrHelper } from '@/helpers/StrHelper'
import { useI18n } from 'vue-i18n'
import AuthService from '@/services/AuthService'
import { useSettingsStore } from '@/services/stores/SettingsStore'
import { storeToRefs } from 'pinia'
import { DriverPaymentMode } from '@/constants/DriverPaymentMode'

const driverEvent = 'image-driver-loaded'
const vehicleEvent = 'image-vehicle-loaded'

export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  setup(__props) {

const driver: Ref<Driver> = ref(new Driver)
const types: Ref<Array<string>> = ref(Constants.DOC_TYPES)
const showPassword = ref(false);
const pathDriver = StorageService.driverPath
const pathVehicle = StorageService.vehiclePath
const route = useRoute()
const { t } = useI18n()
const driverStore = useDriversStore()
const soatExp: Ref<string> = ref('')
const tecExp: Ref<string> = ref('')
const color: Ref<string> = ref(Constants.COLORS[0].hex)
const currentUser = AuthService.getCurrentUser()
const { setLoading } = useLoadingState()
const newBalance = ref(0)
const { branchSelected } = storeToRefs(useSettingsStore())
const schema = object().shape({
  name: string().required().min(3),
  phone: string().required().min(8),
  docType: mixed().oneOf(Constants.DOC_TYPES).required(),
  document: string().required().min(6).max(10),
  brand: string().required().min(3),
  plate: string().required().min(3),
  model: string().required().min(3),
  color: string().matches(new RegExp(/^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/)).required(),
  soat_exp: date().required(),
  tec_exp: date().required()
})

watch(driver, (newDriver) => {
  driver.value.name = StrHelper.toCamelCase(newDriver.name ?? '')
  driver.value.vehicle.brand = StrHelper.toCamelCase(newDriver.vehicle?.brand ?? '')
  driver.value.vehicle.model = StrHelper.toCamelCase(newDriver.vehicle?.model ?? '')
  driver.value.vehicle.plate = StrHelper.formatPlate(newDriver.vehicle?.plate ?? '')
  driver.value.phone = StrHelper.formatNumber(newDriver.phone ?? '')
}, { deep: true })

const schemaEmail = object().shape({
  email: string().required().email()
})

const schemaPassword = object().shape({
  password: string().required()
})


watch(soatExp, (newValue: string) => {
  driver.value.vehicle.soat_exp = DateHelper.dateToUnix(newValue)
})

watch(color, (newColor) => {
  driver.value.vehicle.color = Constants.COLORS.find(c => c.hex == newColor) ?? Constants.COLORS[0]
})

watch(tecExp, (newValue: string) => {
  driver.value.vehicle.tec_exp = DateHelper.dateToUnix(newValue)
})

onBeforeMount(() => {
  const id = route.params.id as string
  const driverTmp = driverStore.findById(id)
  if (driverTmp) {
    driver.value = driverTmp
    if (!driver.value.vehicle.color) driver.value.vehicle.color = Constants.COLORS[0]
    soatExp.value = DateHelper.unixToDate(driverTmp.vehicle.soat_exp, 'YYYY-MM-DD')
    tecExp.value = DateHelper.unixToDate(driverTmp.vehicle.tec_exp, 'YYYY-MM-DD')
    color.value = driverTmp.vehicle.color.hex
  }
  else {
    router.push({ name: 'drivers' })
  }
})

function uploadImgDriver(url: string): void {
  driver.value.photoUrl = url
  updateDriver()
}

function uploadImgVehicle(url: string): void {
  driver.value.vehicle.photoUrl = url
  updateDriver()
}

function updateDriver(): void {
  setLoading(true)
  DriverRepository.update(driver.value).then(async () => {
    setLoading(false)
   await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
    await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function updateEmail(): void {
  setLoading(true)
  DriverRepository.updateEmail(driver.value.id, driver.value.email).then(async () => {
    setLoading(false)
    hide('editGmail')
   await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
   await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function updatePassword(): void {
  setLoading(true)
  DriverRepository.updatePassword(driver.value.id, driver.value.password).then(async () => {
    setLoading(false)
    hide('editPassword')
    await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
   await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function addBalance(): void {
  setLoading(true)
  DriverRepository.addBalance(driver.value, newBalance.value).then(async () => {
    setLoading(false)
    hide('balance-modal')
   await ToastService.toast(ToastService.SUCCESS, i18n.global.t('common.messages.updated'))
  }).catch(async e => {
    setLoading(false)
   await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function onEnable(event: Event): void {
  if (!currentUser || !currentUser.isAdmin()) {
    ToastService.toast(ToastService.ERROR, `${t('users.messages.permissions')}`)
    return
  }
  setLoading(true)
  const target = event.target as HTMLInputElement
  driver.value.enabled_at = target.checked ? dayjs().unix() : 0
  DriverRepository.enable(driver.value.id ?? '', driver.value.enabled_at).then(async () => {
    setLoading(false)
    const message = driver.value.enabled_at == 0 ?
      i18n.global.t('users.messages.disabled') : i18n.global.t('users.messages.enabled')
   await ToastService.toast(ToastService.SUCCESS, message)
  }).catch(async e => {
    setLoading(false)
   await ToastService.toast(ToastService.ERROR, i18n.global.t('common.messages.error'), e.message)
  })
}

function removeDevice(): void {
  driver.value.device = null
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Form), {
        onSubmit: updateDriver,
        "validation-schema": _unref(schema)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create')), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create_driver')), 1)
                  ]),
                  (driver.value.photoUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: driver.value.photoUrl,
                        class: "card-img-top img-driver",
                        alt: "profile_photo"
                      }, null, 8, _hoisted_8))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[25] || (_cache[25] = [
                        _createElementVNode("em", { class: "fa-solid fa-user-astronaut fa-10x" }, null, -1)
                      ]))),
                  _cache[26] || (_cache[26] = _createElementVNode("button", {
                    class: "btn btn-sm btn-info btn-edit-img py-1 px-2",
                    type: "button",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#image-driver"
                  }, [
                    _createElementVNode("span", { class: "btn-inner--icon" }, [
                      _createElementVNode("em", { class: "fas fa-pen" })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.name')), 1),
                    _createVNode(_unref(Field), {
                      name: "name",
                      type: "text",
                      modelValue: driver.value.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((driver.value.name) = $event))
                    }, {
                      default: _withCtx(({ field, errorMessage, meta }) => [
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          class: "form-control form-control-sm",
                          id: "name",
                          "aria-label": "Name",
                          "aria-describedby": "name-addon",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('common.placeholders.name')
                        }, field), null, 16, _hoisted_11), [
                          [_vModelDynamic, field.value]
                        ]),
                        (errorMessage || !meta.dirty)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.email')), 1),
                        _createElementVNode("div", _hoisted_16, [
                          _withDirectives(_createElementVNode("input", {
                            type: "email",
                            class: "form-control form-control-sm",
                            placeholder: _ctx.$t('common.placeholders.email'),
                            readonly: "",
                            "aria-label": "Email",
                            name: "email",
                            "aria-describedby": "email-addon",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((driver.value.email) = $event))
                          }, null, 8, _hoisted_17), [
                            [_vModelText, driver.value.email]
                          ]),
                          _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t('common.actions.edit')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.password')), 1),
                        _createElementVNode("div", _hoisted_20, [
                          _withDirectives(_createElementVNode("input", {
                            type: "password",
                            class: "form-control form-control-sm",
                            placeholder: _ctx.$t('common.placeholders.password'),
                            readonly: "",
                            "aria-label": "Password",
                            name: "password",
                            "aria-describedby": "password-addon",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((driver.value.password) = $event))
                          }, null, 8, _hoisted_21), [
                            [_vModelText, driver.value.password]
                          ]),
                          _createElementVNode("button", _hoisted_22, _toDisplayString(_ctx.$t('common.actions.edit')), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.phone')), 1),
                        _createVNode(_unref(Field), {
                          name: "phone",
                          type: "phone",
                          modelValue: driver.value.phone,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((driver.value.phone) = $event))
                        }, {
                          default: _withCtx(({ errorMessage, meta }) => [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-control form-control-sm",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((driver.value.phone) = $event)),
                              placeholder: _ctx.$t('common.placeholders.phone'),
                              id: "phone",
                              "aria-label": "Phone",
                              "aria-describedby": "phone-addon"
                            }, null, 8, _hoisted_26), [
                              [_vModelText, driver.value.phone]
                            ]),
                            (errorMessage && !meta.dirty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(errorMessage), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.phone2')), 1),
                        _createVNode(_unref(Field), {
                          name: "phone2",
                          type: "phone",
                          modelValue: driver.value.phone2,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((driver.value.phone2) = $event))
                        }, {
                          default: _withCtx(({ errorMessage, meta }) => [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-control form-control-sm",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((driver.value.phone2) = $event)),
                              placeholder: _ctx.$t('common.placeholders.phone2'),
                              id: "phone2",
                              "aria-label": "Phone 2",
                              "aria-describedby": "phone2-addon"
                            }, null, 8, _hoisted_29), [
                              [_vModelText, driver.value.phone2]
                            ]),
                            (errorMessage && !meta.dirty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(errorMessage), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.doc_type')), 1),
                        _createVNode(_unref(Field), {
                          name: "docType",
                          class: "form-select form-select-sm",
                          id: "doc_type",
                          as: "select",
                          modelValue: driver.value.docType,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((driver.value.docType) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(types.value, (type, key) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: key,
                                value: type,
                                selected: ""
                              }, _toDisplayString(type), 9, _hoisted_34))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.document')), 1),
                        _createVNode(_unref(Field), {
                          name: "document",
                          type: "text",
                          modelValue: driver.value.document,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((driver.value.document) = $event))
                        }, {
                          default: _withCtx(({ field, errorMessage, meta }) => [
                            _withDirectives(_createElementVNode("input", _mergeProps({
                              class: "form-control form-control-sm",
                              id: "document",
                              "aria-label": "Document",
                              "aria-describedby": "doc-addon",
                              "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                              placeholder: _ctx.$t('drivers.placeholders.document')
                            }, field), null, 16, _hoisted_36), [
                              [_vModelDynamic, field.value]
                            ]),
                            (errorMessage || !meta.dirty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(errorMessage), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.status')), 1),
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("input", {
                            class: "form-check-input",
                            name: "enable",
                            type: "checkbox",
                            id: "flexSwitchCheckDefault",
                            onChange: onEnable,
                            checked: driver.value.isEnabled()
                          }, null, 40, _hoisted_42),
                          _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t(driver.value.enabled_at ? 'common.fields.enabled' : 'common.fields.disabled')), 1),
                          _createVNode(_unref(ErrorMessage), { name: "enable" })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.fields.payment_mode')), 1),
                        _createVNode(_unref(Field), {
                          name: "paymentMode",
                          class: "form-select form-select-sm",
                          id: "paymentMode",
                          as: "select",
                          modelValue: driver.value.paymentMode,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((driver.value.paymentMode) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("option", {
                              selected: "",
                              value: _unref(DriverPaymentMode).MONTHLY
                            }, _toDisplayString(_ctx.$t('common.placeholders.' + _unref(DriverPaymentMode).MONTHLY)), 9, _hoisted_45),
                            _createElementVNode("option", {
                              value: _unref(DriverPaymentMode).PERCENTAGE
                            }, _toDisplayString(_ctx.$t('common.placeholders.' + _unref(DriverPaymentMode).PERCENTAGE)), 9, _hoisted_46)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.forms.current_balance')), 1),
                      _createElementVNode("span", _hoisted_49, _toDisplayString(driver.value.balance + _unref(branchSelected)?.currency_code), 1)
                    ]),
                    (_unref(AuthService).isAdmin())
                      ? (_openBlock(), _createElementBlock("button", _hoisted_50, _toDisplayString(_ctx.$t('drivers.forms.manage_balance')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('drivers.forms.create_vehicle')), 1)
                  ]),
                  (driver.value.vehicle.photoUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: driver.value.vehicle.photoUrl,
                        class: "card-img-top img-driver",
                        alt: "profile_photo"
                      }, null, 8, _hoisted_53))
                    : (_openBlock(), _createElementBlock("div", _hoisted_54, _cache[27] || (_cache[27] = [
                        _createElementVNode("em", { class: "fa-solid fa-car-side fa-10x" }, null, -1)
                      ]))),
                  _cache[29] || (_cache[29] = _createElementVNode("button", {
                    class: "btn btn-sm btn-info btn-edit-img py-1 px-2",
                    type: "button",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#image-vehicle"
                  }, [
                    _createElementVNode("span", { class: "btn-inner--icon" }, [
                      _createElementVNode("em", { class: "fas fa-pen" })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.brand')), 1),
                    _createVNode(_unref(Field), {
                      name: "brand",
                      type: "text",
                      modelValue: driver.value.vehicle.brand,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((driver.value.vehicle.brand) = $event))
                    }, {
                      default: _withCtx(({ field, errorMessage, meta }) => [
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          class: "form-control form-control-sm",
                          id: "brand",
                          "aria-label": "Brand",
                          "aria-describedby": "brand-addon",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('drivers.placeholders.brand')
                        }, field), null, 16, _hoisted_56), [
                          [_vModelDynamic, field.value]
                        ]),
                        (errorMessage || !meta.dirty)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_57, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_58, [
                    _createElementVNode("div", _hoisted_59, [
                      _createElementVNode("div", _hoisted_60, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.model')), 1),
                        _createVNode(_unref(Field), {
                          name: "model",
                          as: "select",
                          class: "form-select form-select-sm",
                          modelValue: driver.value.vehicle.model,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((driver.value.vehicle.model) = $event))
                        }, {
                          default: _withCtx(({ errorMessage, meta }) => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(DateHelper).arrayYears(), (year) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: year,
                                value: year
                              }, _toDisplayString(year), 9, _hoisted_61))
                            }), 128)),
                            (errorMessage || !meta.dirty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_62, _toDisplayString(errorMessage), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_63, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.plate')), 1),
                        _createVNode(_unref(Field), {
                          name: "plate",
                          type: "text",
                          modelValue: driver.value.vehicle.plate,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((driver.value.vehicle.plate) = $event))
                        }, {
                          default: _withCtx(({ errorMessage, meta }) => [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-control form-control-sm",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((driver.value.vehicle.plate) = $event)),
                              placeholder: _ctx.$t('drivers.placeholders.plate'),
                              id: "plate",
                              "aria-label": "Plate",
                              "aria-describedby": "plate-addon",
                              autocomplete: "none"
                            }, null, 8, _hoisted_64), [
                              [_vModelText, driver.value.vehicle.plate]
                            ]),
                            (errorMessage || !meta.dirty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_65, _toDisplayString(errorMessage), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("div", _hoisted_67, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.placeholders.color')), 1),
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select form-select-sm",
                        id: "color",
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((color).value = $event))
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(Constants).COLORS, (color, key) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: key,
                            value: color.hex
                          }, _toDisplayString(_ctx.$t('common.colors.'
                      + color.name)), 9, _hoisted_68))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, color.value]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_69, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.color')), 1),
                      _createVNode(_unref(Field), {
                        name: "color",
                        modelValue: driver.value.vehicle.color.hex,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((driver.value.vehicle.color.hex) = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control form-control-sm p-0",
                            type: "color",
                            disabled: "",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            "aria-label": "Color",
                            "aria-describedby": "color-addon"
                          }, field, { autocomplete: "none" }), null, 16, _hoisted_70), [
                            [_vModelText, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_71, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_72, [
                    _createElementVNode("div", _hoisted_73, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.soat_exp')), 1),
                      _createVNode(_unref(Field), {
                        name: "soat_exp",
                        type: "date",
                        modelValue: soatExp.value,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((soatExp).value = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control form-control-sm",
                            type: "date",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('drivers.placeholders.soat_exp'),
                            id: "soat_exp",
                            "aria-label": "Soat_exp",
                            "aria-describedby": "soat_exp-addon"
                          }, field, { autocomplete: "none" }), null, 16, _hoisted_74), [
                            [_vModelText, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_76, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.vehicle.tec_exp')), 1),
                      _createVNode(_unref(Field), {
                        name: "tec_exp",
                        type: "date",
                        modelValue: tecExp.value,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((tecExp).value = $event))
                      }, {
                        default: _withCtx(({ field, errorMessage, meta }) => [
                          _withDirectives(_createElementVNode("input", _mergeProps({
                            class: "form-control form-control-sm",
                            type: "date",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                            placeholder: _ctx.$t('drivers.placeholders.tec_exp'),
                            id: "tec_exp",
                            "aria-label": "Pec_exp",
                            "aria-describedby": "tec_exp-addon"
                          }, field, { autocomplete: "none" }), null, 16, _hoisted_77), [
                            [_vModelText, field.value]
                          ]),
                          (errorMessage || !meta.dirty)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_78, _toDisplayString(errorMessage), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_79, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('users.fields.device')), 1),
                    (driver.value.device)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control form-control-sm disabled",
                            disabled: "",
                            "aria-label": "Device",
                            name: "device.name",
                            "aria-describedby": "device-addon",
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((driver.value.device.name) = $event))
                          }, null, 512), [
                            [_vModelText, driver.value.device.name]
                          ]),
                          _createElementVNode("button", {
                            class: "badge bg-danger border-0",
                            id: "removeDevice",
                            type: "button",
                            onClick: _cache[19] || (_cache[19] = ($event: any) => (removeDevice()))
                          }, _cache[28] || (_cache[28] = [
                            _createElementVNode("em", { class: "fa fa-solid fa-trash" }, null, -1)
                          ]))
                        ]))
                      : _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 1,
                          type: "text",
                          class: "form-control form-control-sm",
                          placeholder: _ctx.$t('common.placeholders.device'),
                          disabled: "",
                          "aria-label": "Device",
                          name: "device",
                          "aria-describedby": "password-addon",
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((driver.value.device) = $event))
                        }, null, 8, _hoisted_81)), [
                          [_vModelText, driver.value.device]
                        ])
                  ])
                ])
              ])
            ]),
            _cache[30] || (_cache[30] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", _hoisted_82, [
              _createElementVNode("button", _hoisted_83, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["validation-schema"]),
      _createVNode(ImageLoader, {
        id: 'image-vehicle',
        resourceId: driver.value.id,
        path: _unref(pathVehicle),
        event: vehicleEvent,
        onImageVehicleLoaded: uploadImgVehicle
      }, null, 8, ["resourceId", "path"]),
      _createVNode(ImageLoader, {
        id: 'image-driver',
        resourceId: driver.value.id,
        path: _unref(pathDriver),
        event: driverEvent,
        onImageDriverLoaded: uploadImgDriver
      }, null, 8, ["resourceId", "path"])
    ]),
    _createElementVNode("div", _hoisted_84, [
      _createElementVNode("div", _hoisted_85, [
        _createElementVNode("div", _hoisted_86, [
          _createElementVNode("div", _hoisted_87, [
            _createElementVNode("h5", _hoisted_88, _toDisplayString(_ctx.$t('drivers.forms.add_balance')), 1),
            _cache[31] || (_cache[31] = _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_89, [
            _createElementVNode("div", _hoisted_90, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.forms.current_balance')), 1),
              _createElementVNode("span", _hoisted_91, _toDisplayString(driver.value.balance + _unref(branchSelected)?.currency_code), 1)
            ]),
            _createElementVNode("div", _hoisted_92, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('drivers.forms.add_balance')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "form-control",
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((newBalance).value = $event))
              }, null, 512), [
                [_vModelText, newBalance.value]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_93, [
            _createElementVNode("button", _hoisted_94, _toDisplayString(_ctx.$t('common.actions.close')), 1),
            _createElementVNode("button", {
              onClick: addBalance,
              type: "button",
              class: "btn bg-gradient-primary"
            }, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_95, [
      _createElementVNode("div", _hoisted_96, [
        _createElementVNode("div", _hoisted_97, [
          _createElementVNode("div", _hoisted_98, [
            _createElementVNode("h5", _hoisted_99, _toDisplayString(_ctx.$t('users.forms.edit')), 1),
            _cache[32] || (_cache[32] = _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createVNode(_unref(Form), {
            onSubmit: updateEmail,
            "validation-schema": _unref(schemaEmail)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_100, [
                _createElementVNode("div", _hoisted_101, [
                  _createVNode(_unref(Field), {
                    name: "email",
                    type: "email",
                    modelValue: driver.value.email,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((driver.value.email) = $event))
                  }, {
                    default: _withCtx(({ field }) => [
                      _withDirectives(_createElementVNode("input", _mergeProps({
                        class: "form-control form-control-sm",
                        id: "email",
                        "aria-label": "Email",
                        "aria-describedby": "email-addon",
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                        placeholder: _ctx.$t('common.placeholders.email')
                      }, field), null, 16, _hoisted_102), [
                        [_vModelDynamic, field.value]
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_unref(ErrorMessage), { name: "email" })
                ])
              ]),
              _createElementVNode("div", _hoisted_103, [
                _createElementVNode("button", _hoisted_104, _toDisplayString(_ctx.$t('common.actions.close')), 1),
                _createElementVNode("button", _hoisted_105, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_106, [
      _createElementVNode("div", _hoisted_107, [
        _createElementVNode("div", _hoisted_108, [
          _createElementVNode("div", _hoisted_109, [
            _createElementVNode("h5", _hoisted_110, _toDisplayString(_ctx.$t('users.forms.edit_password')), 1),
            _cache[33] || (_cache[33] = _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createVNode(_unref(Form), {
            onSubmit: updatePassword,
            "validation-schema": _unref(schemaPassword)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_111, [
                _createElementVNode("div", _hoisted_112, [
                  _createVNode(_unref(Field), {
                    name: "password",
                    type: "password",
                    modelValue: driver.value.password,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((driver.value.password) = $event))
                  }, {
                    default: _withCtx(({ field }) => [
                      _createElementVNode("div", _hoisted_113, [
                        _createElementVNode("span", {
                          style: {"cursor":"pointer"},
                          class: "input-group-text",
                          onClick: _cache[23] || (_cache[23] = ($event: any) => (showPassword.value = !showPassword.value))
                        }, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["fa", showPassword.value ? 'fa-eye' : 'fa-eye-slash'])
                          }, null, 2)
                        ]),
                        _withDirectives(_createElementVNode("input", _mergeProps({
                          class: "form-control form-control-sm",
                          id: "password",
                          "aria-label": "Password",
                          "aria-describedby": "password-addon",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          placeholder: _ctx.$t('common.placeholders.password')
                        }, field, {
                          type: showPassword.value ? 'text' : 'password'
                        }), null, 16, _hoisted_114), [
                          [_vModelDynamic, field.value]
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_unref(ErrorMessage), { name: "password" })
                ])
              ]),
              _createElementVNode("div", _hoisted_115, [
                _createElementVNode("button", _hoisted_116, _toDisplayString(_ctx.$t('common.actions.close')), 1),
                _createElementVNode("button", _hoisted_117, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"])
        ])
      ])
    ])
  ], 64))
}
}

})